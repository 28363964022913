import { Link } from 'gatsby'
import styled from "styled-components"

const Button = styled(Link)`
  padding: 10px;
  color: black;
  border: 2px solid black;
  border-radius: 3px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
`

export default Button
