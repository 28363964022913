import React from 'react'
import Header from './header'
import Container from './Container'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import theme from '../styles/theme'
import { Flex, Box, Text } from 'rebass'

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Footer = styled(Flex)`
  background-color: black;
  color: white;
  flex-shrink: 0;
  border-bottom: ${p => p.theme.brandingBorderTop};
`

const Content = styled.div`
  flex-grow: 1;
`

const ContactLink = styled.a`
  color: white !important;
  text-decoration: none;
`

const Layout = ({ children, companyInfo, toggleNav, showNav, siteTitle, white }) => (
  <ThemeProvider theme={theme}>
    <OuterWrapper>
      <Header white={white} siteTitle={siteTitle} toggleNav={toggleNav} showNav={showNav} />
      <Content>{children}</Content>
      <Footer py={[3, 5]} width={1}>
        <Container>
          {companyInfo && (
            <Flex width={1} justifyContent='space-between' flexWrap='wrap'>
              <Box width={[1, 1 / 2]}>
                <Text mr='3' fontWeight='bold'>
                  {companyInfo.name}
                </Text>
                {companyInfo.address1}, {companyInfo.zipCode} {companyInfo.city}
              </Box>
              <Box width={[1, 1 / 2]}>
                {companyInfo.phone && (
                  <Box>
                    <ContactLink href={`tel:${companyInfo.phone}`}>{companyInfo.phone}</ContactLink>
                  </Box>
                )}
                {companyInfo.email && (
                  <Box>
                    <ContactLink href={`mailto:${companyInfo.email}`}>{companyInfo.email}</ContactLink>
                  </Box>
                )}
              </Box>
            </Flex>
          )}
        </Container>
      </Footer>
    </OuterWrapper>
  </ThemeProvider>
)

export default Layout
