// example theme.js

const colors = {
  blue: '#07c',
  lightgray: '#f6f6ff',
  green: "#11d270"
}

export default {
  breakpoints: ['40em', '52em', '64em'],
  sizes: {
    sm: "450px",
    md: "675px",
    lg: "900px"
  },
  fontSizes: [
    16, 20, 28, 48, 64, 128
  ],
  colors,
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  brandingBorderTop: `3px solid ${colors.green}`
}
