import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import styled from 'styled-components'

const NavLink = styled(Link)`
  color: inherit;
  padding: 0.75em 1.5em;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  color: ${p => (p.white ? 'white' : 'black')} !important;
  background: ${p => (p.white ? '#282E32' : 'none')} !important;
  position: relative;
`

const InnerContainer = styled.div`
  display: flex;
  max-width: 1140px;
  box-sizing: border-box;
  border-top: ${p => p.theme.brandingBorderTop};
  padding: 3em 1.5em;
  justify-content: space-between;
  margin: 0 auto;
`

const Branding = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: inherit;
`

const NavButton = styled.div`
  @media (min-width: 550px) {
    display: none;
  }
`

const Navigation = styled.div`
  list-style-type: none;
  display: ${p => (p.showNav ? 'flex' : 'none')};
  z-index: 10000;
  color: black;
  padding-top: 1.5em;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: ${p => (p.showNav ? '100vh' : 'auto')};
  flex-direction: column;
  background: white;

  @media (min-width: 550px) {
    padding: 0;
    color: inherit;
    background: none;
    display: block;
    position: relative;
    top: auto;
    width: auto;
    flex-direction: row;
  }
`

const Header = ({ siteTitle, white = false }) => {
  const [showNav, setToggleNav] = React.useState(false)
  function toggleNav () {
    setToggleNav(!showNav)
  }

  return (
    <Wrapper white={white}>
      <InnerContainer>
        <Branding to='/'>{siteTitle}</Branding>
        <NavButton onClick={toggleNav}>
          {showNav && <Icon symbol='close' />}
          {!showNav && <Icon symbol='hamburger' />}
        </NavButton>

        <Navigation showNav={showNav}>
          <NavLink to='/about/'>methode</NavLink>
          <NavLink to='/schwerpunkte/'>schwerpunkte</NavLink>
          <NavLink to='/contact/'>kontakt</NavLink>
        </Navigation>
      </InnerContainer>
    </Wrapper>
  )
}

export default Header
