import React from 'react'

const strokeStyle = { vectorEffect: 'non-scaling-stroke', strokeWidth: 2 }

const CloseIcon = () => (
  <svg
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <line x1='5' y1='20' x2='20' y2='5' stroke='currentColor' style={strokeStyle} />
    <line x1='5' y1='5  ' x2='20' y2='20' stroke='currentColor' style={strokeStyle} />
  </svg>
)

export default CloseIcon
