import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
import Layout from '../containers/layout'
import Button from '../components/Button'
import { Flex, Box, Heading } from 'rebass'
import styled from 'styled-components'
import BgImg from '../components/images/bg.jpg'

const Pretitle = styled(Heading)`
  color: #bdbdbd;
  text-transform: uppercase;
  margin-top: 0;
`

const Posttitle = styled(Box)`
  color: #bdbdbd !important;
`

const H1 = styled(Heading)`
  font-weight: bold;
  line-height: 1;
  color: white;
  max-width: 25ch;
  margin-top: 0;
`
const BlackBackground = styled(Box)`
  background: url(${BgImg});
  background-size: cover;
  @media (min-width: ${p => p.theme.sizes.md}) {
    min-height: 50vh;
  }
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
`

const ImgOffsetWrapper = styled.div`
  @media (min-width: ${p => p.theme.sizes.sm}) {
    transform: translateY(-100px);
  }
`

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      pretitle
      h1
      _rawPosttitle
      greeting
      _rawDescription
      images {
        asset {
          url
        }
      }
      keywords
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout white>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <BlackBackground pb="4">
        <Container>
          <Box>
            <Pretitle fontSize={[ 14, 18, 24 ]} mb="2">{site.pretitle}</Pretitle>
            <H1 as="h1" fontSize={[ 24, 32, 48 ]} mb="3">{site.h1}</H1>
            <Posttitle mb='0'>
              <BlockContent blocks={site._rawPosttitle || []} />
            </Posttitle>
          </Box>
        </Container>
      </BlackBackground>
      <Container>
        <Flex my='4' flexWrap='wrap'>
          <Box width={[1, 3 / 5]} pr={[0, 5]}>
            <h2>{site.greeting}</h2>
            <Box mb='5'>
              <BlockContent blocks={site._rawDescription || []} />
            </Box>
            <Box mb='5'>
              <Button to='/about'>Mehr über die Methode</Button>
            </Box>
          </Box>
          <Box width={[1, 2 / 5]}>
            <ImgOffsetWrapper>
              {site.images.map((image, index) => (
                <Img src={image.asset.url} key={index} />
              ))}
            </ImgOffsetWrapper>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default IndexPage
