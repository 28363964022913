import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import { createGlobalStyle } from 'styled-components'
import InterRegularWoff from '../styles/inter/Inter-Regular.woff';
import InterRegularWoff2 from '../styles/inter/Inter-Regular.woff2';
import InterBoldWoff from '../styles/inter/Inter-Bold.woff';
import InterBoldWoff2 from '../styles/inter/Inter-Bold.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-display: fallback;
    font-weight: 400;
    src: url(${InterRegularWoff2}) format("woff2"),
        url(${InterRegularWoff}) format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-display: fallback;
    font-weight: 700;
    src: url(${InterBoldWoff2}) format("woff2"),
        url(${InterBoldWoff}) format("woff");
  }

  html {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    line-height: 1.42;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #fff;
    color: #222;
    margin: 0;
  }

  html, body, body > div, body > div > div {
    height: 100%;
  }

  p a {
    color: black;
  }
`

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      email
      phone
      zipCode
      city
      country
    }
  }
`

function LayoutContainer (props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }
        return (
          <>
            <GlobalStyle />
            <Layout
              {...props}
              companyInfo={data.companyInfo}
              siteTitle={data.site.title}
            />
          </>
        )
      }}
    />
  )
}

export default LayoutContainer
