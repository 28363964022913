import styled from 'styled-components'

const Container = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  box-sizing: border-box;
  width: 100%;
`

export default Container
